'use client';

import { IS_DEV } from '@/lib/constants';
import { useReportWebVitals } from 'next/web-vitals';

export default function WebVitals() {
  useReportWebVitals((metric) => {
    IS_DEV && console.log(metric);
  });

  return null;
}
